<template>
    <div></div>
</template>
<script>
export default {
  name: 'Agenda',
  data () {
    return {
      societe: null,
      type_echange: null,
      date: null,
      heure: null,
      duree: null,
      couleur: null,
      titre: null,
      description: null,
      collegues: []
    }
  }
}
</script>